import { render, staticRenderFns } from "./m-video-modal.vue?vue&type=template&id=2de2294e&"
import script from "./m-video-modal.vue?vue&type=script&lang=js&"
export * from "./m-video-modal.vue?vue&type=script&lang=js&"
import style0 from "./m-video-modal.vue?vue&type=style&index=0&id=2de2294e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AVideo: require('/vercel/path0/components/atoms/a-video.vue').default,ASvg: require('/vercel/path0/components/atoms/a-svg.vue').default})
