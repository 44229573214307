
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    name: { type: String, default: null },
    placeholder: { type: String, default: null },
    required: { type: Boolean, default: null },
    type: { type: String, default: 'text' },
    value: { type: String, default: null },
    validateRules: { type: String, default: null },
    success: { type: Boolean, default: false },
    errorState: { type: Boolean, default: false },
    successMessage: { type: String, default: null },
    customError: { type: String, default: null },
    action: { type: String, default: null }
  }
}
