import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ca6e403c = () => interopDefault(import('../pages/life-with-visian.vue' /* webpackChunkName: "pages/life-with-visian" */))
const _5fa2ea70 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6b253ba6 = () => interopDefault(import('../pages/procedure.vue' /* webpackChunkName: "pages/procedure" */))
const _77b30f60 = () => interopDefault(import('../pages/harmony.vue' /* webpackChunkName: "pages/harmony" */))
const _e0ab734c = () => interopDefault(import('../pages/nightvision.vue' /* webpackChunkName: "pages/nightvision" */))
const _53b115d8 = () => interopDefault(import('../pages/lens.vue' /* webpackChunkName: "pages/lens" */))
const _79b33d7a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7341c0e9 = () => interopDefault(import('../pages/dry-eye.vue' /* webpackChunkName: "pages/dry-eye" */))
const _e8ad238a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _52e681e3 = () => interopDefault(import('../pages/evo-icl-is-worth-the-wait.vue' /* webpackChunkName: "pages/evo-icl-is-worth-the-wait" */))
const _63c52364 = () => interopDefault(import('../pages/locale-switcher.vue' /* webpackChunkName: "pages/locale-switcher" */))
const _ec1a661a = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _7e6bc085 = () => interopDefault(import('../pages/testimonials/_slug.vue' /* webpackChunkName: "pages/testimonials/_slug" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9b847f38 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/%C5%BCycie-z-visian",
    component: _ca6e403c,
    name: "life-with-visian___pl-PL"
  }, {
    path: "/%D8%A7%D9%84%D8%A3%D8%B3%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D8%AF%D8%A7%D9%88%D9%84%D8%A9",
    component: _5fa2ea70,
    name: "faq___ar"
  }, {
    path: "/%D8%A7%D9%84%D8%A5%D8%AC%D8%B1%D8%A7%D8%A1",
    component: _6b253ba6,
    name: "procedure___ar"
  }, {
    path: "/%D8%A7%D9%84%D8%AA%D9%86%D8%A7%D8%BA%D9%85",
    component: _77b30f60,
    name: "harmony___ar"
  }, {
    path: "/%D8%A7%D9%84%D8%AD%D9%8A%D8%A7%D8%A9-%D9%85%D8%B9-evo",
    component: _ca6e403c,
    name: "life-with-visian___ar"
  }, {
    path: "/%D8%A7%D9%84%D8%B1%D8%A4%D9%8A%D8%A9-%D8%A7%D9%84%D9%84%D9%8A%D9%84%D9%8A%D8%A9",
    component: _e0ab734c,
    name: "nightvision___ar"
  }, {
    path: "/%D8%A7%D9%84%D8%B9%D8%AF%D8%B3%D8%A9",
    component: _53b115d8,
    name: "lens___ar"
  }, {
    path: "/%D8%A8%D8%A7-%D9%85%D8%A7-%D8%AA%D9%85%D8%A7%D8%B3-%D8%A8%DA%AF%DB%8C%D8%B1%DB%8C%D8%AF",
    component: _79b33d7a,
    name: "contact___fa-IR"
  }, {
    path: "/%D8%AC%D9%81%D8%A7%D9%81-%D8%A7%D9%84%D8%B9%D9%8A%D9%86",
    component: _7341c0e9,
    name: "dry-eye___ar"
  }, {
    path: "/%D8%AC%D9%87%D8%A9-%D8%A7%D9%84%D8%A7%D8%AA%D8%B5%D8%A7%D9%84",
    component: _79b33d7a,
    name: "contact___ar"
  }, {
    path: "/%D8%AE%D8%B4%DA%A9%DB%8C-%DA%86%D8%B4%D9%85",
    component: _7341c0e9,
    name: "dry-eye___fa-IR"
  }, {
    path: "/%D8%AF%DB%8C%D8%AF-%D8%AF%D8%B1-%D8%B4%D8%A8",
    component: _e0ab734c,
    name: "nightvision___fa-IR"
  }, {
    path: "/%D8%B2%D9%86%D8%AF%DA%AF%DB%8C-%D8%A8%D8%A7-evo",
    component: _ca6e403c,
    name: "life-with-visian___fa-IR"
  }, {
    path: "/%D8%B9%D9%85%D9%84",
    component: _6b253ba6,
    name: "procedure___fa-IR"
  }, {
    path: "/%D9%84%D9%86%D8%B2",
    component: _53b115d8,
    name: "lens___fa-IR"
  }, {
    path: "/%D9%87%D9%85%D8%A7%D9%87%D9%86%DA%AF%DB%8C",
    component: _77b30f60,
    name: "harmony___fa-IR"
  }, {
    path: "/%D9%BE%D8%B1%D8%B3%D8%B4-%D9%87%D8%A7%DB%8C-%D9%85%D8%AA%D8%AF%D8%A7%D9%88%D9%84",
    component: _5fa2ea70,
    name: "faq___fa-IR"
  }, {
    path: "/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B",
    component: _79b33d7a,
    name: "contact___ja-JP"
  }, {
    path: "/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F",
    component: _5fa2ea70,
    name: "faq___ja-JP"
  }, {
    path: "/%E3%83%89%E3%83%A9%E3%82%A4%E3%82%A2%E3%82%A4%E3%81%AB%E3%81%AA%E3%82%8A%E3%81%AB%E3%81%8F%E3%81%84",
    component: _7341c0e9,
    name: "dry-eye___ja-JP"
  }, {
    path: "/%E3%83%AC%E3%83%B3%E3%82%BA%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
    component: _53b115d8,
    name: "lens___ja-JP"
  }, {
    path: "/%E5%92%8C%E8%B0%90",
    component: _77b30f60,
    name: "harmony___zh-Hans"
  }, {
    path: "/%E5%A4%9C%E9%96%93%E3%82%82%E8%A6%8B%E3%81%88%E6%96%B9%E3%81%8C%E5%A4%89%E3%82%8F%E3%82%89%E3%81%AA%E3%81%84",
    component: _e0ab734c,
    name: "nightvision___ja-JP"
  }, {
    path: "/%E5%B8%B8%E8%A7%81%E9%97%AE%E7%AD%94",
    component: _5fa2ea70,
    name: "faq___zh-Hans"
  }, {
    path: "/%E5%B9%B2%E7%9C%BC%E7%97%87",
    component: _7341c0e9,
    name: "dry-eye___zh-Hans"
  }, {
    path: "/%E6%89%8B%E6%9C%AF%E6%96%B9%E5%BC%8F",
    component: _6b253ba6,
    name: "procedure___zh-Hans"
  }, {
    path: "/%E6%98%BC%E5%A4%9C%E6%95%8F%E9%94%90%E8%A7%86%E5%8A%9B",
    component: _e0ab734c,
    name: "nightvision___zh-Hans"
  }, {
    path: "/%E7%9B%AE%E3%81%AB%E3%82%84%E3%81%95%E3%81%97%E3%81%84%E6%B2%BB%E7%99%82%E6%B3%95",
    component: _77b30f60,
    name: "harmony___ja-JP"
  }, {
    path: "/%E8%81%94%E7%B3%BB%E6%96%B9%E5%BC%8F",
    component: _79b33d7a,
    name: "contact___zh-Hans"
  }, {
    path: "/%EB%A0%8C%EC%A6%88",
    component: _53b115d8,
    name: "lens___ko-KR"
  }, {
    path: "/%EC%8B%9C%EC%88%A0",
    component: _6b253ba6,
    name: "procedure___ko-KR"
  }, {
    path: "/%EC%95%88%EA%B5%AC-%EA%B1%B4%EC%A1%B0",
    component: _7341c0e9,
    name: "dry-eye___ko-KR"
  }, {
    path: "/%EC%95%BC%EA%B0%84-%EC%8B%9C%EB%A0%A5",
    component: _e0ab734c,
    name: "nightvision___ko-KR"
  }, {
    path: "/%EC%A1%B0%ED%99%94",
    component: _77b30f60,
    name: "harmony___ko-KR"
  }, {
    path: "/a-vida-com-a-icl",
    component: _ca6e403c,
    name: "life-with-visian___pt-PT"
  }, {
    path: "/armon%C3%ADa",
    component: _77b30f60,
    name: "harmony___es-ES"
  }, {
    path: "/armon%C3%ADa",
    component: _77b30f60,
    name: "harmony___es"
  }, {
    path: "/armonia",
    component: _77b30f60,
    name: "harmony___it-IT"
  }, {
    path: "/armonia",
    component: _77b30f60,
    name: "harmony___it-CH"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en-US"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___de-DE"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___fa-IR"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___ar"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___es-ES"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___nl"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___fr-BE"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en-AU"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en-GB"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en-CA"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en-IN"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___es"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___fr-FR"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___it-IT"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___ja-JP"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___ko-KR"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___nb-NO"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___nl-NL"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___sv-SE"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___pt-BR"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___de-CH"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___fr-CA"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___pt-PT"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___pl-PL"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___vi-VN"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___hi-IN"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___en"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___fr-CH"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___it-CH"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog___zh-Hans"
  }, {
    path: "/c%C3%A2u-h%E1%BB%8Fi-th%C6%B0%E1%BB%9Dng-g%E1%BA%B7p",
    component: _5fa2ea70,
    name: "faq___vi-VN"
  }, {
    path: "/ch%E1%BB%A9ng-kh%C3%B4-m%E1%BA%AFt",
    component: _7341c0e9,
    name: "dry-eye___vi-VN"
  }, {
    path: "/cont%C3%A1ctenos",
    component: _79b33d7a,
    name: "contact___es-ES"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en-US"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___nl"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___fr-BE"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en-AU"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en-GB"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en-CA"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en-IN"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___ko-KR"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___fr-CA"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___hi-IN"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en"
  }, {
    path: "/contacte-nos",
    component: _79b33d7a,
    name: "contact___pt-PT"
  }, {
    path: "/contacter",
    component: _79b33d7a,
    name: "contact___fr-FR"
  }, {
    path: "/contacter",
    component: _79b33d7a,
    name: "contact___fr-CH"
  }, {
    path: "/contacto",
    component: _79b33d7a,
    name: "contact___es"
  }, {
    path: "/contato",
    component: _79b33d7a,
    name: "contact___pt-BR"
  }, {
    path: "/contatti",
    component: _79b33d7a,
    name: "contact___it-IT"
  }, {
    path: "/contatti",
    component: _79b33d7a,
    name: "contact___it-CH"
  }, {
    path: "/cu%E1%BB%99c-s%E1%BB%91ng-v%E1%BB%9Bi-evo",
    component: _ca6e403c,
    name: "life-with-visian___vi-VN"
  }, {
    path: "/des-yeux-secs",
    component: _7341c0e9,
    name: "dry-eye___fr-FR"
  }, {
    path: "/des-yeux-secs",
    component: _7341c0e9,
    name: "dry-eye___fr-CH"
  }, {
    path: "/die-linse",
    component: _53b115d8,
    name: "lens___de-DE"
  }, {
    path: "/die-linse",
    component: _53b115d8,
    name: "lens___de-CH"
  }, {
    path: "/droge-ogen",
    component: _7341c0e9,
    name: "dry-eye___nl"
  }, {
    path: "/droge-ogen",
    component: _7341c0e9,
    name: "dry-eye___nl-NL"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en-US"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en-AU"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en-GB"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en-CA"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en-IN"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___hi-IN"
  }, {
    path: "/dry-eye",
    component: _7341c0e9,
    name: "dry-eye___en"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en-US"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___de-DE"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___fa-IR"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___ar"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___es-ES"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___nl"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___fr-BE"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en-AU"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en-GB"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en-CA"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en-IN"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___es"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___fr-FR"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___it-IT"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___ja-JP"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___ko-KR"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___nb-NO"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___nl-NL"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___sv-SE"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___pt-BR"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___de-CH"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___fr-CA"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___pt-PT"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___pl-PL"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___vi-VN"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___hi-IN"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___en"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___fr-CH"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___it-CH"
  }, {
    path: "/evo-icl-is-worth-the-wait",
    component: _52e681e3,
    name: "evo-icl-is-worth-the-wait___zh-Hans"
  }, {
    path: "/evo-icl%E6%99%B6%E4%BD%93",
    component: _53b115d8,
    name: "lens___zh-Hans"
  }, {
    path: "/evo-visian-icl-lens",
    component: _53b115d8,
    name: "lens___en-US"
  }, {
    path: "/evo%E7%94%9F%E6%B4%BB",
    component: _ca6e403c,
    name: "life-with-visian___zh-Hans"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en-US"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___de-DE"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___fr-BE"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en-AU"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en-GB"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en-CA"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en-IN"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___fr-FR"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___it-IT"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___ko-KR"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___de-CH"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___fr-CA"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___pl-PL"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___hi-IN"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___fr-CH"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___it-CH"
  }, {
    path: "/fekv",
    component: _5fa2ea70,
    name: "faq___sv-SE"
  }, {
    path: "/harmoni",
    component: _77b30f60,
    name: "harmony___nb-NO"
  }, {
    path: "/harmoni",
    component: _77b30f60,
    name: "harmony___sv-SE"
  }, {
    path: "/harmonia",
    component: _77b30f60,
    name: "harmony___pt-BR"
  }, {
    path: "/harmonia",
    component: _77b30f60,
    name: "harmony___pt-PT"
  }, {
    path: "/harmonia",
    component: _77b30f60,
    name: "harmony___pl-PL"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___de-DE"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___nl"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___fr-BE"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___fr-FR"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___nl-NL"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___de-CH"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___fr-CA"
  }, {
    path: "/harmonie",
    component: _77b30f60,
    name: "harmony___fr-CH"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en-US"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en-AU"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en-GB"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en-CA"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en-IN"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___hi-IN"
  }, {
    path: "/harmony",
    component: _77b30f60,
    name: "harmony___en"
  }, {
    path: "/icl%E4%BD%93%E9%A8%93%E8%AB%87",
    component: _ca6e403c,
    name: "life-with-visian___ja-JP"
  }, {
    path: "/icl%E6%89%8B%E8%A1%93",
    component: _6b253ba6,
    name: "procedure___ja-JP"
  }, {
    path: "/ingrepp",
    component: _6b253ba6,
    name: "procedure___sv-SE"
  }, {
    path: "/intervention",
    component: _6b253ba6,
    name: "procedure___fr-BE"
  }, {
    path: "/keine-trockenen-augen",
    component: _7341c0e9,
    name: "dry-eye___de-DE"
  }, {
    path: "/keine-trockenen-augen",
    component: _7341c0e9,
    name: "dry-eye___de-CH"
  }, {
    path: "/kontakt",
    component: _79b33d7a,
    name: "contact___nb-NO"
  }, {
    path: "/kontakt",
    component: _79b33d7a,
    name: "contact___sv-SE"
  }, {
    path: "/kontakt",
    component: _79b33d7a,
    name: "contact___pl-PL"
  }, {
    path: "/kontaktieren-sie-uns",
    component: _79b33d7a,
    name: "contact___de-DE"
  }, {
    path: "/kontaktieren-sie-uns",
    component: _79b33d7a,
    name: "contact___de-CH"
  }, {
    path: "/la-vida-con-lentes-icl",
    component: _ca6e403c,
    name: "life-with-visian___es-ES"
  }, {
    path: "/la-vie-avec-evo",
    component: _ca6e403c,
    name: "life-with-visian___fr-CA"
  }, {
    path: "/la-vie-avec-icl",
    component: _ca6e403c,
    name: "life-with-visian___fr-CH"
  }, {
    path: "/la-vita-con-icl",
    component: _ca6e403c,
    name: "life-with-visian___it-IT"
  }, {
    path: "/la-vita-con-icl",
    component: _ca6e403c,
    name: "life-with-visian___it-CH"
  }, {
    path: "/leben-mit-evo",
    component: _ca6e403c,
    name: "life-with-visian___de-DE"
  }, {
    path: "/leben-mit-evo",
    component: _ca6e403c,
    name: "life-with-visian___de-CH"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___nl"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___en-AU"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___en-GB"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___en-CA"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___en-IN"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___nl-NL"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___hi-IN"
  }, {
    path: "/lens",
    component: _53b115d8,
    name: "lens___en"
  }, {
    path: "/lente",
    component: _53b115d8,
    name: "lens___es-ES"
  }, {
    path: "/lente",
    component: _53b115d8,
    name: "lens___es"
  }, {
    path: "/lente",
    component: _53b115d8,
    name: "lens___pt-PT"
  }, {
    path: "/lentes",
    component: _53b115d8,
    name: "lens___pt-BR"
  }, {
    path: "/lenti",
    component: _53b115d8,
    name: "lens___it-IT"
  }, {
    path: "/lenti",
    component: _53b115d8,
    name: "lens___it-CH"
  }, {
    path: "/lentille",
    component: _53b115d8,
    name: "lens___fr-FR"
  }, {
    path: "/lentille",
    component: _53b115d8,
    name: "lens___fr-CH"
  }, {
    path: "/lentilles",
    component: _53b115d8,
    name: "lens___fr-BE"
  }, {
    path: "/lentilles",
    component: _53b115d8,
    name: "lens___fr-CA"
  }, {
    path: "/li%C3%AAn-h%E1%BB%87",
    component: _79b33d7a,
    name: "contact___vi-VN"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___en-US"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___nl"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___fr-BE"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___en-AU"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___en-GB"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___en-CA"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___es"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___fr-FR"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___ko-KR"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___nb-NO"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___nl-NL"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___hi-IN"
  }, {
    path: "/life-with-evo",
    component: _ca6e403c,
    name: "life-with-visian___en"
  }, {
    path: "/life-with-icl",
    component: _ca6e403c,
    name: "life-with-visian___en-IN"
  }, {
    path: "/linse",
    component: _53b115d8,
    name: "lens___nb-NO"
  }, {
    path: "/linser",
    component: _53b115d8,
    name: "lens___sv-SE"
  }, {
    path: "/liv-med-visian",
    component: _ca6e403c,
    name: "life-with-visian___sv-SE"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en-US"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___de-DE"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___fa-IR"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___ar"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___es-ES"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___nl"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___fr-BE"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en-AU"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en-GB"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en-CA"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en-IN"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___es"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___fr-FR"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___it-IT"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___ja-JP"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___ko-KR"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___nb-NO"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___nl-NL"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___sv-SE"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___pt-BR"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___de-CH"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___fr-CA"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___pt-PT"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___pl-PL"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___vi-VN"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___hi-IN"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___en"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___fr-CH"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___it-CH"
  }, {
    path: "/locale-switcher",
    component: _63c52364,
    name: "locale-switcher___zh-Hans"
  }, {
    path: "/nachtsicht",
    component: _e0ab734c,
    name: "nightvision___de-DE"
  }, {
    path: "/nachtsicht",
    component: _e0ab734c,
    name: "nightvision___de-CH"
  }, {
    path: "/nachtvisie",
    component: _e0ab734c,
    name: "nightvision___nl-NL"
  }, {
    path: "/nachtzicht",
    component: _e0ab734c,
    name: "nightvision___nl"
  }, {
    path: "/nattesyn",
    component: _e0ab734c,
    name: "nightvision___nb-NO"
  }, {
    path: "/nattvision",
    component: _e0ab734c,
    name: "nightvision___sv-SE"
  }, {
    path: "/neem-contact-op-met",
    component: _79b33d7a,
    name: "contact___nl-NL"
  }, {
    path: "/nightvision",
    component: _e0ab734c,
    name: "nightvision___en-AU"
  }, {
    path: "/nightvision",
    component: _e0ab734c,
    name: "nightvision___en-GB"
  }, {
    path: "/nightvision",
    component: _e0ab734c,
    name: "nightvision___en-CA"
  }, {
    path: "/nightvision",
    component: _e0ab734c,
    name: "nightvision___en-IN"
  }, {
    path: "/nightvision",
    component: _e0ab734c,
    name: "nightvision___en"
  }, {
    path: "/ojo-seco",
    component: _7341c0e9,
    name: "dry-eye___es"
  }, {
    path: "/olho-seco",
    component: _7341c0e9,
    name: "dry-eye___pt-BR"
  }, {
    path: "/perguntas-frequentes",
    component: _5fa2ea70,
    name: "faq___pt-BR"
  }, {
    path: "/perguntas-frequentes",
    component: _5fa2ea70,
    name: "faq___pt-PT"
  }, {
    path: "/preguntas-frecuentes",
    component: _5fa2ea70,
    name: "faq___es-ES"
  }, {
    path: "/preguntas-frecuentes",
    component: _5fa2ea70,
    name: "faq___es"
  }, {
    path: "/proc%C3%A9dure",
    component: _6b253ba6,
    name: "procedure___fr-FR"
  }, {
    path: "/proc%C3%A9dure",
    component: _6b253ba6,
    name: "procedure___fr-CA"
  }, {
    path: "/proc%C3%A9dure",
    component: _6b253ba6,
    name: "procedure___fr-CH"
  }, {
    path: "/procedimento",
    component: _6b253ba6,
    name: "procedure___pt-BR"
  }, {
    path: "/procedimento",
    component: _6b253ba6,
    name: "procedure___pt-PT"
  }, {
    path: "/procedimiento",
    component: _6b253ba6,
    name: "procedure___es-ES"
  }, {
    path: "/procedimiento",
    component: _6b253ba6,
    name: "procedure___es"
  }, {
    path: "/procedura",
    component: _6b253ba6,
    name: "procedure___it-IT"
  }, {
    path: "/procedura",
    component: _6b253ba6,
    name: "procedure___pl-PL"
  }, {
    path: "/procedura",
    component: _6b253ba6,
    name: "procedure___it-CH"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en-US"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___nl"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en-AU"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en-GB"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en-CA"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en-IN"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___nl-NL"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___hi-IN"
  }, {
    path: "/procedure",
    component: _6b253ba6,
    name: "procedure___en"
  }, {
    path: "/prosedyren",
    component: _6b253ba6,
    name: "procedure___nb-NO"
  }, {
    path: "/s%C3%A9cheresse-oculaire",
    component: _7341c0e9,
    name: "dry-eye___fr-BE"
  }, {
    path: "/s%C3%A9cheresse-oculaire",
    component: _7341c0e9,
    name: "dry-eye___fr-CA"
  }, {
    path: "/s%E1%BB%B1-ph%C3%B9-h%E1%BB%A3p",
    component: _77b30f60,
    name: "harmony___vi-VN"
  }, {
    path: "/secchezza-oculare",
    component: _7341c0e9,
    name: "dry-eye___it-IT"
  }, {
    path: "/secchezza-oculare",
    component: _7341c0e9,
    name: "dry-eye___it-CH"
  }, {
    path: "/secura-ocular",
    component: _7341c0e9,
    name: "dry-eye___pt-PT"
  }, {
    path: "/sharp-clear-vision-day-and-night",
    component: _e0ab734c,
    name: "nightvision___en-US"
  }, {
    path: "/sharp-clear-vision-day-and-night",
    component: _e0ab734c,
    name: "nightvision___hi-IN"
  }, {
    path: "/sin-sequedad-ocular",
    component: _7341c0e9,
    name: "dry-eye___es-ES"
  }, {
    path: "/soczewki",
    component: _53b115d8,
    name: "lens___pl-PL"
  }, {
    path: "/t%C3%B8rre-%C3%B8yne",
    component: _7341c0e9,
    name: "dry-eye___nb-NO"
  }, {
    path: "/t%E1%BA%A7m-nh%C3%ACn-ban-%C4%91%C3%AAm",
    component: _e0ab734c,
    name: "nightvision___vi-VN"
  }, {
    path: "/th%E1%BA%A5u-k%C3%ADnh",
    component: _53b115d8,
    name: "lens___vi-VN"
  }, {
    path: "/th%E1%BB%A7-thu%E1%BA%ADt",
    component: _6b253ba6,
    name: "procedure___vi-VN"
  }, {
    path: "/torra-%C3%B6gon",
    component: _7341c0e9,
    name: "dry-eye___sv-SE"
  }, {
    path: "/vanlige-sp%C3%B8rsm%C3%A5l",
    component: _5fa2ea70,
    name: "faq___nb-NO"
  }, {
    path: "/veelgestelde-vragen",
    component: _5fa2ea70,
    name: "faq___nl"
  }, {
    path: "/veelgestelde-vragen",
    component: _5fa2ea70,
    name: "faq___nl-NL"
  }, {
    path: "/verfahren",
    component: _6b253ba6,
    name: "procedure___de-DE"
  }, {
    path: "/verfahren",
    component: _6b253ba6,
    name: "procedure___de-CH"
  }, {
    path: "/vida-com-visian",
    component: _ca6e403c,
    name: "life-with-visian___pt-BR"
  }, {
    path: "/vis%C3%A3o-noturna",
    component: _e0ab734c,
    name: "nightvision___pt-BR"
  }, {
    path: "/vis%C3%A3o-noturna",
    component: _e0ab734c,
    name: "nightvision___pt-PT"
  }, {
    path: "/visi%C3%B3n-nocturna",
    component: _e0ab734c,
    name: "nightvision___es-ES"
  }, {
    path: "/visi%C3%B3n-nocturna",
    component: _e0ab734c,
    name: "nightvision___es"
  }, {
    path: "/vision-nocturne",
    component: _e0ab734c,
    name: "nightvision___fr-BE"
  }, {
    path: "/vision-nocturne",
    component: _e0ab734c,
    name: "nightvision___fr-FR"
  }, {
    path: "/vision-nocturne",
    component: _e0ab734c,
    name: "nightvision___fr-CA"
  }, {
    path: "/vision-nocturne",
    component: _e0ab734c,
    name: "nightvision___fr-CH"
  }, {
    path: "/vista-notturna",
    component: _e0ab734c,
    name: "nightvision___it-IT"
  }, {
    path: "/vista-notturna",
    component: _e0ab734c,
    name: "nightvision___it-CH"
  }, {
    path: "/widzenie-w-nocy",
    component: _e0ab734c,
    name: "nightvision___pl-PL"
  }, {
    path: "/zesp%C3%B3%C5%82-suchego-oka",
    component: _7341c0e9,
    name: "dry-eye___pl-PL"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en-US"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___de-DE"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___fa-IR"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___ar"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___es-ES"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___nl"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___fr-BE"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en-AU"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en-GB"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en-CA"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en-IN"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___es"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___fr-FR"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___it-IT"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___ja-JP"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___ko-KR"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___nb-NO"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___nl-NL"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___sv-SE"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___pt-BR"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___de-CH"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___fr-CA"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___pt-PT"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___pl-PL"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___vi-VN"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___hi-IN"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___en"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___fr-CH"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___it-CH"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug___zh-Hans"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en-US"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___de-DE"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___fa-IR"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___ar"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___es-ES"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___nl"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___fr-BE"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en-AU"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en-GB"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en-CA"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en-IN"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___es"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___fr-FR"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___it-IT"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___ja-JP"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___ko-KR"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___nb-NO"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___nl-NL"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___sv-SE"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___pt-BR"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___de-CH"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___fr-CA"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___pt-PT"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___pl-PL"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___vi-VN"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___hi-IN"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___en"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___fr-CH"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___it-CH"
  }, {
    path: "/testimonials/:slug?",
    component: _7e6bc085,
    name: "testimonials-slug___zh-Hans"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en-US"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___de-DE"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fa-IR"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___ar"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___es-ES"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___nl"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fr-BE"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en-AU"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en-GB"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en-CA"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en-IN"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___es"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fr-FR"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___it-IT"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___ja-JP"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___ko-KR"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___nb-NO"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___nl-NL"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___sv-SE"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___pt-BR"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___de-CH"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fr-CA"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___pt-PT"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___pl-PL"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___vi-VN"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___hi-IN"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fr-CH"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___it-CH"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___zh-Hans"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en-US"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___de-DE"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___fa-IR"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___ar"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___es-ES"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___nl"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___fr-BE"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en-AU"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en-GB"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en-CA"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en-IN"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___es"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___fr-FR"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___it-IT"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___ja-JP"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___ko-KR"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___nb-NO"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___nl-NL"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___sv-SE"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___pt-BR"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___de-CH"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___fr-CA"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___pt-PT"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___pl-PL"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___vi-VN"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___hi-IN"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___fr-CH"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___it-CH"
  }, {
    path: "/:slug",
    component: _9b847f38,
    name: "slug___zh-Hans"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
