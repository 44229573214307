
import '../atoms/a-icon'

export default {
  props: {
    hasNewsletter: { type: Boolean },
    hasExtraBelow: { type: Boolean, default: false },
    hasExtraInline: { type: Boolean, default: false },
    hasStaarLink: { type: Boolean, default: false },
    iclBaseUrl: { type: String, default: '' }
  },
  data () {
    return {
      date: new Date(),
      formEmailValue: '',
      formError: false,
      formLoading: false,
      formSuccess: false,
      isConfirmed: false,
      isClicked: false
    }
  },
  methods: {
    submitFormEmail (e) {
      e.preventDefault()
      this.isClicked = true

      if (this.formLoading || this.formSuccess || (!this.isConfirmed && this.hasNewsletter)) { return }

      this.formLoading = true

      const body = {
        contact: {
          Email: this.formEmailValue
        },
        trigger: '0008'
      }

      fetch(process.env.apiProxyUrl + '/autopilot/captureUser.js', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }).then((response) => {
        if (!response.ok) {
          this.formError = true
          throw new Error('Network response was not OK')
        }
        this.formSuccess = true
        this.formLoading = false
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'emailSubmissions',
          event_category: 'Email Submissions',
          event_action: 'Success',
          event_label: window.location.href
        })
      }).catch(() => {
        this.formError = true
      })
    },
    openLocaleSwitcher () {
      this.$root.$emit('showLocaleSwitcher')
    },
    handleFormKeyup () {
      if (this.formSuccess) {
        this.formSuccess = false
      }
    }
  }
}
