import { render, staticRenderFns } from "./c-the-footer.vue?vue&type=template&id=433297de&"
import script from "./c-the-footer.vue?vue&type=script&lang=js&"
export * from "./c-the-footer.vue?vue&type=script&lang=js&"
import style0 from "./c-the-footer.vue?vue&type=style&index=0&id=433297de&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AButton: require('/vercel/path0/components/atoms/a-button.vue').default,ASvg: require('/vercel/path0/components/atoms/a-svg.vue').default,MFormSearch: require('/vercel/path0/components/molecules/m-form-search.vue').default,AIcon: require('/vercel/path0/components/atoms/a-icon.vue').default})
