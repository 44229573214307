import { render, staticRenderFns } from "./default.vue?vue&type=template&id=10b85f00&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CTheHeader: require('/vercel/path0/components/organisms/c-the-header.vue').default,ASvg: require('/vercel/path0/components/atoms/a-svg.vue').default,MPinnedButton: require('/vercel/path0/components/molecules/m-pinned-button.vue').default,CReferencesModal: require('/vercel/path0/components/organisms/c-references-modal.vue').default,CKrNewsletterModal: require('/vercel/path0/components/organisms/c-kr-newsletter-modal.vue').default,CLocaleSwitcher: require('/vercel/path0/components/organisms/c-locale-switcher.vue').default,CTheFooter: require('/vercel/path0/components/organisms/c-the-footer.vue').default})
