
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { gql } from 'graphql-tag'

export default {
  props: {
    global: { type: Object, default: null }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    content () {
      if (this.global?.referencesModal) {
        return this.global.referencesModal[0]
      } else {
        return null
      }
    }
  },
  fragments: {
    field: gql`
      fragment referencesModal on referencesModal_MatrixField {
        ... on referencesModal_block_BlockType {
          id
          typeHandle
          heading
          copy
        }
      }
    `
  },
  mounted () {
    this.$root.$on('showReferencesModal', () => {
      this.isOpen = true
      disableBodyScroll(this.$el)
    })
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.hide()
      }
    })
  },
  methods: {
    hide () {
      this.isOpen = false
      clearAllBodyScrollLocks()
    }
  }
}
