
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      blocksActive: [],
      blocksActiveDown: [],
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100)
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field : null
    }
  },
  fragments: {
    field: gql`
      fragment imageCopyBlocks on imageCopyBlocks_MatrixField {
        ... on imageCopyBlocks_block_BlockType {
          id
          typeHandle
          idName
          theme
          imageAlignInline
          image {
            url
            title
          }
          video {
            url
            title
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `,
    field2: gql`
      fragment imageCopyBlocks2 on imageCopyBlocks2_MatrixField {
        ... on imageCopyBlocks2_block_BlockType {
          id
          typeHandle
          idName
          theme
          imageAlignInline
          image {
            url
            title
          }
          video {
            url
            title
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `,
    field3: gql`
      fragment imageCopyBlocks3 on imageCopyBlocks3_MatrixField {
        ... on imageCopyBlocks3_block_BlockType {
          id
          typeHandle
          idName
          theme
          imageAlignInline
          image {
            url
            title
          }
          video {
            url
            title
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `
  },
  mounted () {
    if (this.content) {
      this.blocksActive = Array(this.$refs.block.length).fill(false)
      this.blocksActiveDown = Array(this.$refs.block.length).fill(false)
      window.addEventListener('scroll', this.handleWindowScrollThrottled)
      this.handleWindowScroll()
    }
  },
  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.handleWindowScrollThrottled)
    }
  },
  methods: {
    handleWindowScroll () {
      this.$refs.block.forEach((element, index) => {
        this.$set(
          this.blocksActive,
          index,
          (element.getBoundingClientRect().top <= window.innerHeight * 0.5) && (element.getBoundingClientRect().bottom >= window.innerHeight * 0.5)
        )
      })
      this.$refs.block.forEach((element, index) => {
        this.$set(
          this.blocksActiveDown,
          index,
          (element.getBoundingClientRect().top <= window.innerHeight * 0.5)
        )
      })
    }
  }
}
