
export default {
  props: {
    href: { type: String, default: null },
    target: { type: String, default: null }
  },
  computed: {
    isRendered () {
      return this.$store.state.mPinnedButton.isRendered
    },
    isVisible () {
      return this.$store.state.mPinnedButton.isVisible
    }
  }
}
