
import { gql } from 'graphql-tag'

export default {
  props: {
    field: { type: Array, default: null }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field : null
    },
    mainBlock () {
      if (this.content) {
        const find = this.content.find((item) => {
          return item.typeHandle === 'main'
        })

        if (find !== undefined) {
          return find
        }
      }

      return {}
    },
    firstTableHeader () {
      const find = this.content.find((item) => {
        return item.typeHandle === 'tableHeader'
      })

      return find !== undefined ? find : null
    }
  },
  fragments: {
    field: gql`
      fragment comparisonTable on comparisonTable_MatrixField {
        ... on comparisonTable_main_BlockType {
          id
          typeHandle
          idName
          heading
          copy
          disableColumns
        }
        ... on comparisonTable_tableHeader_BlockType {
          id
          typeHandle
          tableHeader {
            mainHeading
            columnHeading1
            columnHeading2
            columnHeading3
          }
        }
        ... on comparisonTable_tableBody_BlockType {
          id
          typeHandle
          tableBody {
            rowHeading
            data1
            data2
            data3
          }
        }
        ... on comparisonTable_tableFooter_BlockType {
          id
          typeHandle
          tableFooter {
            mainNote
            columnNote1
            columnNote2
            columnNote3
          }
        }
      }
    `
  }
}
