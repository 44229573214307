
export default {
  props: {
    totalPages: { type: Number, default: 1 },
    currentPage: { type: Number, default: 1 },
    theme: { type: String, default: null }
  },
  methods: {
    changePage (index) {
      if (index < 1 || index > this.totalPages) {
        return
      }
      this.$emit('change-page', event, index)
    }
  }
}
