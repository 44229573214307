
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  props: {
    open: { type: Boolean, required: false, default: false },
    video: { type: String, required: false, default: null }
  },
  watch: {
    open (val) {
      if (val) {
        this.$refs.player.play()
        disableBodyScroll(this.$refs.modal)
        document.addEventListener('keydown', this.handleKeydown)
      } else {
        document.removeEventListener('keydown', this.handleKeydown)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.$refs.player.pause()
      enableBodyScroll(this.$refs.modal)
    },
    handleKeydown (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    }
  }
}
