
import { gql } from 'graphql-tag'
import Glide from '@glidejs/glide'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      slider: null,
      sliderIndex: 0,
      slideMediaPlayed: []
    }
  },
  computed: {
    content () {
      const block = this.field.find(x => x.__typename === 'faqVideoSlider_block_BlockType')
      const slides = this.field.map((f) => {
        if (f.__typename === 'faqVideoSlider_faqVideos_BlockType') {
          return {
            primaryCopy: f.primaryCopy,
            secondaryCopy: f.secondaryCopy,
            video: {
              title: f.video[0].embeddedAsset.title,
              description: f.video[0].embeddedAsset.description,
              url: f.video[0].embeddedAsset.url,
              jsonUrl: f.video[0].url,
              type: f.video[0].embeddedAsset.type
            }
          }
        }
        return null
      })

      const filteredSlides = slides.filter(s => s !== null)

      return {
        ...block,
        slides: filteredSlides
      }
    }
  },
  fragments: {
    field: gql`
      fragment faqSlider on faqVideoSlider_MatrixField {
        ... on faqVideoSlider_block_BlockType {
          __typename
          idName
          heading
        }
        ... on faqVideoSlider_faqVideos_BlockType {
          __typename
          primaryCopy
          secondaryCopy
          video {
            title
            slug
            url
            embeddedAsset {
              title
              description
              url
              type
            }
          }
        }
      }
    `
  },
  mounted () {
    if (this.content?.slides?.length) {
      this.slideMediaPlayed = Array(this.$refs.slideMedia?.length).fill(false)
      this.slider = new Glide(this.$refs.slider, {
        direction: document.documentElement.getAttribute('dir'),
        gap: 0,
        rewind: false
      })
      this.slider.mount()
      this.slider.on('run', () => {
        this.sliderIndex = this.slider.index
        this.$refs.slideMedia?.forEach(component => component?.pause && component.pause())
      })
    }
  },
  beforeDestroy () {
    if (this.content && this.slider) {
      this.slider.destroy()
    }
  },
  methods: {
    handlePaginationChangePage (event, index) {
      this.slider.go(`=${index - 1}`)
    },
    playSlideMedia (slideIndex) {
      this.$set(this.slideMediaPlayed, slideIndex, true)
      this.$refs.slideMedia[slideIndex]?.play && this.$refs.slideMedia[slideIndex].play()
    }
  }
}
