
export default {
  props: {
    component: { type: String, default: null },
    to: { type: String, default: null },
    href: { type: String, default: null },
    target: { type: String, default: null },
    ariaLabel: { type: String, default: null },
    type: { type: String, default: null },
    field: { type: Object, default: null },
    variant: { type: String, default: null },
    size: { type: String, default: 'md' }
  },
  computed: {
    dynamicComponent () {
      if (this.component) {
        return this.component
      } else if (this.dynamicTo) {
        return 'nuxt-link'
      } else if (this.dynamicHref) {
        return 'a'
      } else if (this.type) {
        return 'button'
      } else {
        return 'div'
      }
    },
    isRendered () {
      return this.$slots.default || this.dynamicText
    },
    dynamicTo () {
      if (this.component && this.component !== 'a') {
        return this.dynamicHref ? encodeURI(this.dynamicHref) : undefined
      } else if (
        !this.component &&
        this.dynamicHref &&
        this.dynamicHref.startsWith('/') &&
        !this.dynamicHref.startsWith('//')
      ) {
        return this.dynamicHref ? encodeURI(this.dynamicHref) : undefined
      } else {
        return null
      }
    },
    dynamicHref () {
      return this.to || this.href || (this.field ? this.field.url : undefined)
    },
    dynamicTarget () {
      return this.target || (this.field ? this.field.target : undefined)
    },
    dynamicAriaLabel () {
      return this.ariaLabel || (this.field ? this.field.ariaLabel : undefined)
    },
    dynamicText () {
      return this.field ? this.field.text || this.field.title : undefined
    },
    dynamicIcon () {
      return this.icon || (this.field ? this.field.icon : {})
    },
    dynamicClass () {
      return [
        `a-button a-button--component_${this.dynamicComponent}`,
        {
          'inline-flex items-center justify-center font-bold text-center uppercase transition-all duration-300 text-button align-middle': this.variant,
          'border-2 border-transparent a-button--variant_icon': this.variant === 'icon',
          'a-button--variant_link': this.variant === 'link',
          'border-2 border-transparent a-button--variant_button': this.variant === 'button',
          'a-button--size_md': ['button', 'icon'].includes(this.variant) && this.size === 'md',
          'a-button--size_lg': ['button', 'icon'].includes(this.variant) && this.size === 'lg',
          'a-button--size_xl': ['button', 'icon'].includes(this.variant) && this.size === 'xl'
        }
      ]
    }
  },
  methods: {
    handleClick (event) {
      if (this.dynamicHref && this.dynamicHref.startsWith('#')) {
        event.preventDefault()
        const targetHash = this.dynamicHref
        const targetEl = targetHash === '#'
          ? document.documentElement
          : document.getElementById(targetHash.replace(/^#/, ''))
        if (targetEl) {
          window.scrollTo({
            top: targetEl.getBoundingClientRect().top + window.pageYOffset -
              (parseInt(getComputedStyle(document.documentElement).getPropertyValue('--scroll-spacing-top'), 10) || 0),
            behavior: 'smooth'
          })
        }
      }

      if (this.dynamicHref && (this.dynamicHref.includes(this.$i18n.localeProperties.hostUrl) || this.dynamicHref.includes('doctors.discovericl.com'))) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'searchIntention',
          event_category: 'Doctor or Location Intention',
          event_action: this.$parent.$options.name,
          event_label: window.location.href
        })
      } else if (this.dynamicHref && (this.dynamicHref.includes(this.$i18n.localeProperties.quizUrl) || this.dynamicHref.includes('quiz.discovericl.com'))) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'quizIntention',
          event_category: 'Quiz Intention',
          event_action: this.$parent.$options.name,
          event_label: window.location.href
        })
      }
    }
  }
}
