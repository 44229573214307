
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'

export default {
  props: {
    field: { type: Array, default: null },
    lensBranding: { type: String, default: '' }
  },
  data () {
    return {
      active: false,
      modalOpen: false
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  fragments: {
    field: gql`
      fragment aboutVideo on aboutVideo_MatrixField {
        ... on aboutVideo_block_BlockType {
          id
          typeHandle
          idName
          heading
          copy
          buttonLabel
          video {
            url
            title
            embeddedAsset {
              url
              title
            }
          }
        }
      }
    `
  },
  mounted () {
    this.thottledScroll = throttle(this.handleScroll, 100)
    if (this.content) {
      window.addEventListener('scroll', this.thottledScroll)
      this.handleScroll()
    }
  },
  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.thottledScroll)
    }
  },
  methods: {
    handleScroll () {
      this.active = (this.$refs.container.getBoundingClientRect().top <= window.innerHeight * 0.5)
    }
  }
}
