
import locales from '../../i18n/locales.json'

export default {
  props: {
    canSetCookie: { type: Boolean, default: true }
  },
  data () {
    return {
      selectValue: null,
      locales
    }
  },
  mounted () {
    if (this.canSetCookie) {
      this.$refs.locales.querySelectorAll('a').forEach((element) => {
        element.addEventListener('click', this.setDocumentCookie)
      })
    }
  },
  beforeDestroy () {
    if (this.canSetCookie) {
      this.$refs.locales.querySelectorAll('a').forEach((element) => {
        element.removeEventListener('click', this.setDocumentCookie)
      })
    }
  },
  methods: {
    setDocumentCookie (ev) {
      ev.preventDefault()
      const element = ev.target
      const elementLocale = this.$util.formatLocale(element.dataset.locale)
      const cookie = `locale=${elementLocale}; domain=.${process.env.baseDomain}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
      document.cookie = cookie
      window.location = element.href
    }
  }
}
