
import CheckTrueIcon from '~/assets/icons/check-true.svg?inline'
import CheckFalseIcon from '~/assets/icons/check-false.svg?inline'

const icons = [
  ['check-true', CheckTrueIcon],
  ['check-false', CheckFalseIcon]
]

export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator (value) {
        return icons.some(row => row[0] === value)
      }
    }
  },
  computed: {
    computedIcon () {
      const find = icons.find((row) => {
        return row[0] === this.icon
      })
      return (find ? find[1] : null)
    }
  }
}
