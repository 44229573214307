
import { gql } from 'graphql-tag'
import Glide from '@glidejs/glide'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      slider: null,
      sliderIndex: 0,
      testimonialMediaPlayed: []
    }
  },
  computed: {
    content () {
      return this.field && this.field.length
        ? {
            ...this.field[0],
            testimonials: [
              ...(this.field[0]?.testimonials || []).map(testimonial => ({
                video: testimonial.testimonialVideo,
                image: testimonial.testimonialImage,
                quote: testimonial.testimonialQuote,
                caption: `${testimonial.title}, ${testimonial.testimonialOccupation}`
              }))
            ]
          }
        : null
    }
  },
  fragments: {
    field: gql`
      fragment testimonialsSlider on testimonialsSlider_MatrixField {
        ... on testimonialsSlider_block_BlockType {
          id
          typeHandle
          idName
          heading
          testimonials {
            id
            title
            ... on testimonials_testimonials_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 564, height: 317, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
            }
            ... on testimonialPages_testimonialPages_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 564, height: 317, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
            }
          }
        }
      }
    `
  },
  mounted () {
    if (this.content?.testimonials?.length) {
      this.testimonialMediasPlayed = Array(this.$refs.testimonialMedia?.length).fill(false)
      this.slider = new Glide(this.$refs.slider, {
        direction: document.documentElement.getAttribute('dir'),
        gap: 0,
        rewind: false
      })
      this.slider.mount()
      this.slider.on('run', () => {
        this.sliderIndex = this.slider.index
        this.$refs.testimonialMedia?.forEach(component => component?.pause && component.pause())
      })
    }
  },
  beforeDestroy () {
    if (this.content && this.slider) {
      this.slider.destroy()
    }
  },
  methods: {
    handlePaginationChangePage (event, index) {
      this.slider.go(`=${index - 1}`)
    },
    playTestimonialMedia (testimonialIndex) {
      this.$set(this.testimonialMediaPlayed, testimonialIndex, true)
      this.$refs.testimonialMedia[testimonialIndex]?.play && this.$refs.testimonialMedia[testimonialIndex].play()
    }
  }
}
