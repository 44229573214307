import { render, staticRenderFns } from "./c-locale-switcher.vue?vue&type=template&id=1363f3ab&"
import script from "./c-locale-switcher.vue?vue&type=script&lang=js&"
export * from "./c-locale-switcher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CLocaleSwitcherBody: require('/vercel/path0/components/organisms/c-locale-switcher-body.vue').default,MModal: require('/vercel/path0/components/molecules/m-modal.vue').default})
