import { gql } from 'graphql-tag'
import CAboutVideo from '~/components/organisms/c-about-video'
import CCenteredCopy from '~/components/organisms/c-centered-copy'
import CComparisonTable from '~/components/organisms/c-comparison-table'
import CCounter from '~/components/organisms/c-counter'
import CFindADoctor from '~/components/organisms/c-find-a-doctor'
import CReferencesModal from '~/components/organisms/c-references-modal'
import CSplitTestimonial from '~/components/organisms/c-split-testimonial'
import CStats from '~/components/organisms/c-stats'
import CTestimonialsSlider from '~/components/organisms/c-testimonials-slider'
import CBackgroundImageCopy from '~/components/organisms/c-background-image-copy'
import CCtaBox from '~/components/organisms/c-cta-box'
import CImageCopyBlocks from '~/components/organisms/c-image-copy-blocks'
import CFaqSlider from '~/components/organisms/c-faq-slider'

export default ({ app }, inject) => {
  const getGlobals = () => app.apolloProvider.defaultClient.query({
    query: gql`
      query GlobalSet($handle: [String]!, $site: [String]!) {
        globalSet(handle: $handle, site: $site) {
          handle
          name
          ... on global_GlobalSet {
            aboutVideo {
              ...aboutVideo
            }
            backgroundImageCopy {
              ...backgroundImageCopy
            }
            centeredCopy {
              ...centeredCopy
            }
            comparisonTable {
              ...comparisonTable
            }
            counter {
              ...counter
            }
            ctaBox {
              ...ctaBox
            }
            findADoctor {
              ...findADoctor
            }
            imageCopyBlocks {
              ...imageCopyBlocks
            }
            referencesModal {
              ...referencesModal
            }
            splitTestimonial {
              ...splitTestimonial
            }
            stats {
              ...stats
            }
            testimonialsSlider {
              ...testimonialsSlider
            }
            faqVideoSlider {
              ...faqSlider
            }
          }
        }
      }
      ${CAboutVideo.fragments.field}
      ${CBackgroundImageCopy.fragments.field}
      ${CCenteredCopy.fragments.field}
      ${CComparisonTable.fragments.field}
      ${CCounter.fragments.field}
      ${CCtaBox.fragments.field}
      ${CFindADoctor.fragments.field}
      ${CImageCopyBlocks.fragments.field}
      ${CReferencesModal.fragments.field}
      ${CSplitTestimonial.fragments.field}
      ${CStats.fragments.field}
      ${CTestimonialsSlider.fragments.field}
      ${CFaqSlider.fragments.field}
    `,
    variables: {
      handle: 'global',
      site: app.i18n.localeProperties.handle
    }
  }).then(res => res?.data?.globalSet)

  inject('globals', getGlobals)
}
