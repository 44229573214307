
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default {
  props: {
    global: { type: Object, default: null }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    content () {
      if (this.global?.referencesModal) {
        return this.global.referencesModal[0]
      } else {
        return null
      }
    }
  },
  mounted () {
    this.$root.$on('showKrReferencesModal', () => {
      this.isOpen = true
      disableBodyScroll(this.$el)
    })
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.hide()
      }
    })
  },
  methods: {
    hide () {
      this.isOpen = false
      clearAllBodyScrollLocks()
    }
  }
}
