
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      animate: false
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  mounted () {
    this.scrollEvent = throttle(this.handleScroll, 100)
    window.addEventListener('scroll', this.scrollEvent)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollEvent)
  },
  methods: {
    handleScroll (event) {
      if (this.content) {
        const elementRect = this.$refs.cta.getBoundingClientRect()
        if (elementRect.top < window.innerHeight - (elementRect.height / 3)) {
          this.animate = true
        } else {
          this.animate = false
        }
      }
    }
  },
  fragments: {
    field: gql`
      fragment ctaBox on ctaBox_MatrixField {
        ... on ctaBox_block_BlockType {
          id
          typeHandle
          idName
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          theme
          label
          heading
          buttonText
          hyperlink {
            type
            url
            text
            target
          }
        }
      }
    `,
    customComponentsBlogField: gql`
      fragment customComponentsBlog_ctaBox on customComponentsBlog_MatrixField {
        ... on customComponentsBlog_ctaBox_BlockType {
          id
          typeHandle
          idName
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          theme
          label
          heading
          buttonText
          hyperlink {
            type
            url
            text
            target
          }
        }
      }
    `
  }
}
