
export default {
  data () {
    return {
      global: null
    }
  },
  async fetch () {
    this.global = await this.$globals()
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addDirAttribute: true, addSeoAttributes: true })
    return {
      htmlAttrs: i18nHead.htmlAttrs
    }
  },
  computed: {
    countryCode () {
      switch (this.$i18n.localeProperties.iso) {
        case 'ar':
          return 'ar'
        case 'es':
          return 'la'
        case 'nl':
          return 'be'
        case 'en':
          return 'ea'
        case 'zh-Hans':
          return 'us'
        default:
          return this.$i18n.localeProperties.iso.slice(3)
      }
    }
  }
}
