
export default {
  props: {
    isOpen: { type: Boolean },
    brand: { type: String, default: '' },
    iclBaseUrl: { type: String, default: '' },
    doctorsUrl: { type: String, default: '' },
    quizUrl: { type: String, default: '' }
  },
  data () {
    return {
      activeSubmenu: null
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown (event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.$emit('close')
      }
    },
    openSubmenu (menu) {
      this.activeSubmenu = menu
    },
    closeSubmenu () {
      this.activeSubmenu = null
    },
    closeMenu () {
      this.$emit('close')
      setTimeout(() => {
        this.activeSubmenu = null
      }, 400)
    },
    openLocaleSwitcher () {
      this.closeMenu()
      this.$root.$emit('showLocaleSwitcher')
    },
    handleQuizButtonClick () {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'quizIntention',
        event_category: 'Quiz Intention',
        event_action: 'Header component',
        event_label: window.location.href
      })
      this.closeMenu()
    },
    handleDoctorsButtonClick () {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'searchIntention',
        event_category: 'Doctor or Location Intention',
        event_action: 'Header component',
        event_label: window.location.href
      })
      this.closeMenu()
    }
  }
}
