export default ({ env, i18n }, inject) => {
  const prefix = i18n.localeProperties.domain.split('.').shift()
  const url = `/${prefix}/actions/freeform/api/form`

  const post = formData => fetch(url, {
    method: 'post',
    cache: 'no-cache',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: formData
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const div = document.createElement('div')
      div.innerHTML = res.html
      return { ...res, html: div.querySelector('form') }
    })

  inject('freeform', post)
}
