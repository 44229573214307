export const AButton = () => import('../../components/atoms/a-button.vue' /* webpackChunkName: "components/a-button" */).then(c => wrapFunctional(c.default || c))
export const ACheckbox = () => import('../../components/atoms/a-checkbox.vue' /* webpackChunkName: "components/a-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AIcon = () => import('../../components/atoms/a-icon.vue' /* webpackChunkName: "components/a-icon" */).then(c => wrapFunctional(c.default || c))
export const AInput = () => import('../../components/atoms/a-input.vue' /* webpackChunkName: "components/a-input" */).then(c => wrapFunctional(c.default || c))
export const ASelect = () => import('../../components/atoms/a-select.vue' /* webpackChunkName: "components/a-select" */).then(c => wrapFunctional(c.default || c))
export const ASvg = () => import('../../components/atoms/a-svg.vue' /* webpackChunkName: "components/a-svg" */).then(c => wrapFunctional(c.default || c))
export const ATextarea = () => import('../../components/atoms/a-textarea.vue' /* webpackChunkName: "components/a-textarea" */).then(c => wrapFunctional(c.default || c))
export const AVideo = () => import('../../components/atoms/a-video.vue' /* webpackChunkName: "components/a-video" */).then(c => wrapFunctional(c.default || c))
export const MArticleLink = () => import('../../components/molecules/m-article-link.vue' /* webpackChunkName: "components/m-article-link" */).then(c => wrapFunctional(c.default || c))
export const MDropdown = () => import('../../components/molecules/m-dropdown.vue' /* webpackChunkName: "components/m-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MFormSearch = () => import('../../components/molecules/m-form-search.vue' /* webpackChunkName: "components/m-form-search" */).then(c => wrapFunctional(c.default || c))
export const MFreeformModal = () => import('../../components/molecules/m-freeform-modal.vue' /* webpackChunkName: "components/m-freeform-modal" */).then(c => wrapFunctional(c.default || c))
export const MModal = () => import('../../components/molecules/m-modal.vue' /* webpackChunkName: "components/m-modal" */).then(c => wrapFunctional(c.default || c))
export const MPaginationDots = () => import('../../components/molecules/m-pagination-dots.vue' /* webpackChunkName: "components/m-pagination-dots" */).then(c => wrapFunctional(c.default || c))
export const MPinnedButton = () => import('../../components/molecules/m-pinned-button.vue' /* webpackChunkName: "components/m-pinned-button" */).then(c => wrapFunctional(c.default || c))
export const MPinnedSubnav = () => import('../../components/molecules/m-pinned-subnav.vue' /* webpackChunkName: "components/m-pinned-subnav" */).then(c => wrapFunctional(c.default || c))
export const MTestimonial = () => import('../../components/molecules/m-testimonial.vue' /* webpackChunkName: "components/m-testimonial" */).then(c => wrapFunctional(c.default || c))
export const MVideoModal = () => import('../../components/molecules/m-video-modal.vue' /* webpackChunkName: "components/m-video-modal" */).then(c => wrapFunctional(c.default || c))
export const MVideoPip = () => import('../../components/molecules/m-video-pip.vue' /* webpackChunkName: "components/m-video-pip" */).then(c => wrapFunctional(c.default || c))
export const C404 = () => import('../../components/organisms/c-404.vue' /* webpackChunkName: "components/c404" */).then(c => wrapFunctional(c.default || c))
export const C500 = () => import('../../components/organisms/c-500.vue' /* webpackChunkName: "components/c500" */).then(c => wrapFunctional(c.default || c))
export const CAboutVideo = () => import('../../components/organisms/c-about-video.vue' /* webpackChunkName: "components/c-about-video" */).then(c => wrapFunctional(c.default || c))
export const CArticleListing = () => import('../../components/organisms/c-article-listing.vue' /* webpackChunkName: "components/c-article-listing" */).then(c => wrapFunctional(c.default || c))
export const CArticleMasthead = () => import('../../components/organisms/c-article-masthead.vue' /* webpackChunkName: "components/c-article-masthead" */).then(c => wrapFunctional(c.default || c))
export const CBackToTop = () => import('../../components/organisms/c-back-to-top.vue' /* webpackChunkName: "components/c-back-to-top" */).then(c => wrapFunctional(c.default || c))
export const CBackgroundImageCopy = () => import('../../components/organisms/c-background-image-copy.vue' /* webpackChunkName: "components/c-background-image-copy" */).then(c => wrapFunctional(c.default || c))
export const CBackgroundImageList = () => import('../../components/organisms/c-background-image-list.vue' /* webpackChunkName: "components/c-background-image-list" */).then(c => wrapFunctional(c.default || c))
export const CCenteredCopy = () => import('../../components/organisms/c-centered-copy.vue' /* webpackChunkName: "components/c-centered-copy" */).then(c => wrapFunctional(c.default || c))
export const CComparisonTable = () => import('../../components/organisms/c-comparison-table.vue' /* webpackChunkName: "components/c-comparison-table" */).then(c => wrapFunctional(c.default || c))
export const CContactForm = () => import('../../components/organisms/c-contact-form.vue' /* webpackChunkName: "components/c-contact-form" */).then(c => wrapFunctional(c.default || c))
export const CCopyBlock = () => import('../../components/organisms/c-copy-block.vue' /* webpackChunkName: "components/c-copy-block" */).then(c => wrapFunctional(c.default || c))
export const CCounter = () => import('../../components/organisms/c-counter.vue' /* webpackChunkName: "components/c-counter" */).then(c => wrapFunctional(c.default || c))
export const CCtaBoxLegacy = () => import('../../components/organisms/c-cta-box-legacy.vue' /* webpackChunkName: "components/c-cta-box-legacy" */).then(c => wrapFunctional(c.default || c))
export const CCtaBox = () => import('../../components/organisms/c-cta-box.vue' /* webpackChunkName: "components/c-cta-box" */).then(c => wrapFunctional(c.default || c))
export const CCustomComponent = () => import('../../components/organisms/c-custom-component.vue' /* webpackChunkName: "components/c-custom-component" */).then(c => wrapFunctional(c.default || c))
export const CExpandableFaqs = () => import('../../components/organisms/c-expandable-faqs.vue' /* webpackChunkName: "components/c-expandable-faqs" */).then(c => wrapFunctional(c.default || c))
export const CFaqSlider = () => import('../../components/organisms/c-faq-slider.vue' /* webpackChunkName: "components/c-faq-slider" */).then(c => wrapFunctional(c.default || c))
export const CFindADoctor = () => import('../../components/organisms/c-find-a-doctor.vue' /* webpackChunkName: "components/c-find-a-doctor" */).then(c => wrapFunctional(c.default || c))
export const CFormie = () => import('../../components/organisms/c-formie.vue' /* webpackChunkName: "components/c-formie" */).then(c => wrapFunctional(c.default || c))
export const CFreeform = () => import('../../components/organisms/c-freeform.vue' /* webpackChunkName: "components/c-freeform" */).then(c => wrapFunctional(c.default || c))
export const CFullWidthTestimonial = () => import('../../components/organisms/c-full-width-testimonial.vue' /* webpackChunkName: "components/c-full-width-testimonial" */).then(c => wrapFunctional(c.default || c))
export const CFullWidthVideo = () => import('../../components/organisms/c-full-width-video.vue' /* webpackChunkName: "components/c-full-width-video" */).then(c => wrapFunctional(c.default || c))
export const CImageCopyBlocks = () => import('../../components/organisms/c-image-copy-blocks.vue' /* webpackChunkName: "components/c-image-copy-blocks" */).then(c => wrapFunctional(c.default || c))
export const CImageCopySlides = () => import('../../components/organisms/c-image-copy-slides.vue' /* webpackChunkName: "components/c-image-copy-slides" */).then(c => wrapFunctional(c.default || c))
export const CImageSectionBreak = () => import('../../components/organisms/c-image-section-break.vue' /* webpackChunkName: "components/c-image-section-break" */).then(c => wrapFunctional(c.default || c))
export const CKrNewsletterModal = () => import('../../components/organisms/c-kr-newsletter-modal.vue' /* webpackChunkName: "components/c-kr-newsletter-modal" */).then(c => wrapFunctional(c.default || c))
export const CKrNewsletter = () => import('../../components/organisms/c-kr-newsletter.vue' /* webpackChunkName: "components/c-kr-newsletter" */).then(c => wrapFunctional(c.default || c))
export const CKrTempImageList = () => import('../../components/organisms/c-kr-temp-image-list.vue' /* webpackChunkName: "components/c-kr-temp-image-list" */).then(c => wrapFunctional(c.default || c))
export const CLocaleSwitcherBody = () => import('../../components/organisms/c-locale-switcher-body.vue' /* webpackChunkName: "components/c-locale-switcher-body" */).then(c => wrapFunctional(c.default || c))
export const CLocaleSwitcherScreen = () => import('../../components/organisms/c-locale-switcher-screen.vue' /* webpackChunkName: "components/c-locale-switcher-screen" */).then(c => wrapFunctional(c.default || c))
export const CLocaleSwitcher = () => import('../../components/organisms/c-locale-switcher.vue' /* webpackChunkName: "components/c-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const CMasthead = () => import('../../components/organisms/c-masthead.vue' /* webpackChunkName: "components/c-masthead" */).then(c => wrapFunctional(c.default || c))
export const CMinorImageMasthead = () => import('../../components/organisms/c-minor-image-masthead.vue' /* webpackChunkName: "components/c-minor-image-masthead" */).then(c => wrapFunctional(c.default || c))
export const CNull = () => import('../../components/organisms/c-null.vue' /* webpackChunkName: "components/c-null" */).then(c => wrapFunctional(c.default || c))
export const CPartialTextMasthead = () => import('../../components/organisms/c-partial-text-masthead.vue' /* webpackChunkName: "components/c-partial-text-masthead" */).then(c => wrapFunctional(c.default || c))
export const CPhotoCollage = () => import('../../components/organisms/c-photo-collage.vue' /* webpackChunkName: "components/c-photo-collage" */).then(c => wrapFunctional(c.default || c))
export const CQuestionsAndAnswers = () => import('../../components/organisms/c-questions-and-answers.vue' /* webpackChunkName: "components/c-questions-and-answers" */).then(c => wrapFunctional(c.default || c))
export const CReferencesModal = () => import('../../components/organisms/c-references-modal.vue' /* webpackChunkName: "components/c-references-modal" */).then(c => wrapFunctional(c.default || c))
export const CRelatedArticles = () => import('../../components/organisms/c-related-articles.vue' /* webpackChunkName: "components/c-related-articles" */).then(c => wrapFunctional(c.default || c))
export const CSimpleCopy = () => import('../../components/organisms/c-simple-copy.vue' /* webpackChunkName: "components/c-simple-copy" */).then(c => wrapFunctional(c.default || c))
export const CSmallTestimonialGrid = () => import('../../components/organisms/c-small-testimonial-grid.vue' /* webpackChunkName: "components/c-small-testimonial-grid" */).then(c => wrapFunctional(c.default || c))
export const CSplitTestimonial = () => import('../../components/organisms/c-split-testimonial.vue' /* webpackChunkName: "components/c-split-testimonial" */).then(c => wrapFunctional(c.default || c))
export const CStats = () => import('../../components/organisms/c-stats.vue' /* webpackChunkName: "components/c-stats" */).then(c => wrapFunctional(c.default || c))
export const CStoryGallery = () => import('../../components/organisms/c-story-gallery.vue' /* webpackChunkName: "components/c-story-gallery" */).then(c => wrapFunctional(c.default || c))
export const CTestimonialMasthead = () => import('../../components/organisms/c-testimonial-masthead.vue' /* webpackChunkName: "components/c-testimonial-masthead" */).then(c => wrapFunctional(c.default || c))
export const CTestimonialsSlider = () => import('../../components/organisms/c-testimonials-slider.vue' /* webpackChunkName: "components/c-testimonials-slider" */).then(c => wrapFunctional(c.default || c))
export const CTheFooter = () => import('../../components/organisms/c-the-footer.vue' /* webpackChunkName: "components/c-the-footer" */).then(c => wrapFunctional(c.default || c))
export const CTheHeader = () => import('../../components/organisms/c-the-header.vue' /* webpackChunkName: "components/c-the-header" */).then(c => wrapFunctional(c.default || c))
export const CTheMenu = () => import('../../components/organisms/c-the-menu.vue' /* webpackChunkName: "components/c-the-menu" */).then(c => wrapFunctional(c.default || c))
export const CVideoMaskMasthead = () => import('../../components/organisms/c-video-mask-masthead.vue' /* webpackChunkName: "components/c-video-mask-masthead" */).then(c => wrapFunctional(c.default || c))
export const CWhyIcl = () => import('../../components/organisms/c-why-icl.vue' /* webpackChunkName: "components/c-why-icl" */).then(c => wrapFunctional(c.default || c))
export const CWtwSlides = () => import('../../components/organisms/c-wtw-slides.vue' /* webpackChunkName: "components/c-wtw-slides" */).then(c => wrapFunctional(c.default || c))
export const CWysiwyg = () => import('../../components/organisms/c-wysiwyg.vue' /* webpackChunkName: "components/c-wysiwyg" */).then(c => wrapFunctional(c.default || c))
export const LBlogLegacyBlog = () => import('../../components/templates/l-blog-legacy-blog.vue' /* webpackChunkName: "components/l-blog-legacy-blog" */).then(c => wrapFunctional(c.default || c))
export const LBlog = () => import('../../components/templates/l-blog.vue' /* webpackChunkName: "components/l-blog" */).then(c => wrapFunctional(c.default || c))
export const LCustomComponentsBlog = () => import('../../components/templates/l-custom-components-blog.vue' /* webpackChunkName: "components/l-custom-components-blog" */).then(c => wrapFunctional(c.default || c))
export const LCustomComponentsLandingPage = () => import('../../components/templates/l-custom-components-landing-page.vue' /* webpackChunkName: "components/l-custom-components-landing-page" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesBlog = () => import('../../components/templates/l-general-pages-blog.vue' /* webpackChunkName: "components/l-general-pages-blog" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesContact = () => import('../../components/templates/l-general-pages-contact.vue' /* webpackChunkName: "components/l-general-pages-contact" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesDryEye = () => import('../../components/templates/l-general-pages-dry-eye.vue' /* webpackChunkName: "components/l-general-pages-dry-eye" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesFaq = () => import('../../components/templates/l-general-pages-faq.vue' /* webpackChunkName: "components/l-general-pages-faq" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesHarmony = () => import('../../components/templates/l-general-pages-harmony.vue' /* webpackChunkName: "components/l-general-pages-harmony" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesLandingPage = () => import('../../components/templates/l-general-pages-landing-page.vue' /* webpackChunkName: "components/l-general-pages-landing-page" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesLegalPage = () => import('../../components/templates/l-general-pages-legal-page.vue' /* webpackChunkName: "components/l-general-pages-legal-page" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesLens = () => import('../../components/templates/l-general-pages-lens.vue' /* webpackChunkName: "components/l-general-pages-lens" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesLifeWithVisian = () => import('../../components/templates/l-general-pages-life-with-visian.vue' /* webpackChunkName: "components/l-general-pages-life-with-visian" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesNightvision = () => import('../../components/templates/l-general-pages-nightvision.vue' /* webpackChunkName: "components/l-general-pages-nightvision" */).then(c => wrapFunctional(c.default || c))
export const LGeneralPagesProcedure = () => import('../../components/templates/l-general-pages-procedure.vue' /* webpackChunkName: "components/l-general-pages-procedure" */).then(c => wrapFunctional(c.default || c))
export const LHomepage = () => import('../../components/templates/l-homepage.vue' /* webpackChunkName: "components/l-homepage" */).then(c => wrapFunctional(c.default || c))
export const LTestimonialPages = () => import('../../components/templates/l-testimonial-pages.vue' /* webpackChunkName: "components/l-testimonial-pages" */).then(c => wrapFunctional(c.default || c))
export const LUi = () => import('../../components/templates/l-ui.vue' /* webpackChunkName: "components/l-ui" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
