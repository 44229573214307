
export default {
  data () {
    return {
      open: false
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown)
    this.$root.$on('showLocaleSwitcher', () => {
      this.open = true
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown (event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModal()
      }
    },
    closeModal () {
      this.open = false
    },
    handleSelectChange () {
      location.href = this.selectValue
    }
  }
}
