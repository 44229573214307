
import { gql } from 'graphql-tag'

export default {
  props: {
    field: { type: Array, default: null },
    margins: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      regions: [],
      formSearchValue: '',
      doctorsBaseUrl: this.$i18n.localeProperties.hostUrl
    }
  },
  async fetch () {
    try {
      const res = await this.$apollo.query({
        client: 'doctors',
        query: gql`
          query Entry($site: [String]) {
            entries(section: "regions", site: $site, orderBy: "title ASC") {
              ... on regions_regions_Entry {
                id
                slug
                title
                regionLocations {
                  id
                }
              }
            }
            globalSet(handle: "global", site: $site) {
              ... on global_GlobalSet {
                featuredRegions {
                  ... on regions_regions_Entry {
                    id
                    slug
                    title
                  }
                }
              }
            }
          }
        `,
        variables: {
          site: this.$i18n.localeProperties.handle
        }
      })

      if (res?.data?.entries?.length) {
        const { entries, globalSet: { featuredRegions } } = res.data
        const featuredIds = featuredRegions.map(region => region.id)
        const filteredRegions = entries
          .filter(region => !featuredIds.includes(region.id) && region.regionLocations?.length)
          .map(({ id, slug, title }) => ({ id, slug, title }))
        const payload = featuredRegions.length
          ? [...featuredRegions, { title: '-' }, ...filteredRegions]
          : filteredRegions

        this.regions = [
          {
            label: this.$t('select-a-region').toUpperCase(),
            value: '',
            disabled: true
          },
          ...payload
            .map(entry => ({
              label: entry.title,
              value: entry.slug,
              disabled: !entry.slug
            }))
        ]
      }
    } catch (e) {
      console.error(e)
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  fragments: {
    field: gql`
      fragment findADoctor on findADoctor_MatrixField {
        ... on findADoctor_block_BlockType {
          id
          typeHandle
          idName
          backgroundImage {
            url @transform(mode: "crop", width: 1440, height: 558, immediately: true)
            title
          }
          heading
          copy
        }
      }
    `
  },
  methods: {
    handleButtonCurrentLocationClick (event) {
      event.preventDefault()
      const handleSuccess = (position) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'doctorSearch',
          event_category: 'Doctor Search',
          event_action: 'Find a Doctor component before footer',
          event_label: `lat=${position.coords.latitude}&lng=${position.coords.longitude}`
        })
        window.location.href = `${this.doctorsBaseUrl}?lat=${position.coords.latitude}&lng=${position.coords.longitude}`
      }
      const handleError = () => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'doctorSearch',
          event_category: 'Doctor Search',
          event_action: 'Find a Doctor component before footer',
          event_label: ''
        })
        window.location.href = this.doctorsBaseUrl
      }
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    },
    submitDoctorSearch (event) {
      /* eslint-disable no-useless-escape */
      const piiRegex = /(\S+@\S+)/g

      if (piiRegex.test(this.formSearchValue)) {
        console.log('Refusing to search for something that looks like personal information.')
        event.preventDefault()
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'doctorSearch',
        event_category: 'Doctor Search',
        event_action: 'Find a Doctor component before footer',
        event_label: this.formSearchValue
      })
    },
    submitRegionSearch (region) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'doctorSearch',
        event_category: 'Doctor Search',
        event_action: 'Find a Doctor component before footer',
        event_label: region
      })
      window.location.href = `${this.doctorsBaseUrl}?q=${region}`
    }
  }
}
