
export default {
  props: {
    list: { type: Array, default: null },
    isOpen: { type: Boolean }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('focusout', this.handleFocusOut)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('focusout', this.handleFocusOut)
  },
  methods: {
    handleKeyDown (event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.$emit('close')
      }
    },
    handleFocusOut (event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.$emit('close')
      }
    }
  }
}
