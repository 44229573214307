
export default {
  props: {
    invalid: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    value: { type: String, default: '' }
  },
  data () {
    return {
      dirty: false
    }
  },
  methods: {
    onBlur () {
      this.dirty = true
    },
    onChange () {
      this.dirty = true
    }
  }
}
