
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'
import Hls from 'hls.js'

export default {
  props: {
    field: { type: Array, default: null }
  },

  data () {
    return {
      blocksActive: [],
      blocksActiveDown: [],
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100)
    }
  },

  computed: {
    content () {
      return this.field && this.field.length ? this.field : null
    }
  },

  fragments: {
    field: gql`
      fragment backgroundImageCopy on backgroundImageCopy_MatrixField {
        ... on backgroundImageCopy_block_BlockType {
          id
          typeHandle
          idName
          theme
          bodyAlignInline
          bodyAlignBlock
          backgroundImageFade {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageFadeMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundVideo {
            url
            title
            embeddedAsset {
              title
              url
              providerName
            }
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `,
    field2: gql`
      fragment backgroundImageCopy2 on backgroundImageCopy2_MatrixField {
        ... on backgroundImageCopy2_block_BlockType {
          id
          typeHandle
          idName
          theme
          bodyAlignInline
          bodyAlignBlock
          backgroundImageFade {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageFadeMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundVideo {
            url
            title
            embeddedAsset {
              title
              url
              providerName
            }
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `,
    field3: gql`
      fragment backgroundImageCopy3 on backgroundImageCopy3_MatrixField {
        ... on backgroundImageCopy3_block_BlockType {
          id
          typeHandle
          idName
          theme
          bodyAlignInline
          bodyAlignBlock
          backgroundImageFade {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageFadeMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundVideo {
            url
            title
            embeddedAsset {
              title
              url
              providerName
            }
          }
          heading
          copy
          button {
            type
            url
            text
            target
          }
        }
      }
    `
  },

  mounted () {
    if (this.content) {
      this.blocksActive = Array(this.$refs.block.length).fill(false)
      this.blocksActiveDown = Array(this.$refs.block.length).fill(false)
      window.addEventListener('scroll', this.handleWindowScrollThrottled)
      this.handleWindowScroll()
    }

    if (this.field[0].backgroundVideo[0]?.url.length) {
      const video = document.getElementById('backgroundImageCopyBackgroundVideo')
      if (this.field[0].backgroundVideo[0]?.embeddedAsset?.url.length) {
        if (Hls.isSupported()) {
          const hls = new Hls()
          hls.attachMedia(video)
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(
              this.field[0].backgroundVideo[0].embeddedAsset.url
            )
          })
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = this.field[0].backgroundVideo[0].embeddedAsset.url
        }
      } else {
        video.src = this.field[0].backgroundVideo[0].url
      }
    }
  },

  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.handleWindowScrollThrottled)
    }
  },

  methods: {
    handleWindowScroll () {
      this.$refs.block.forEach((element, index) => {
        const blockIsActive = (element.getBoundingClientRect().top <= window.innerHeight * 0.5) && (element.getBoundingClientRect().bottom >= window.innerHeight * 0.5)
        this.$set(
          this.blocksActive,
          index,
          blockIsActive
        )
      })
      this.$refs.block.forEach((element, index) => {
        const blockIsActiveDown = (element.getBoundingClientRect().top <= window.innerHeight * 0.5)
        if (this.$refs.backgroundVideo && this.$refs.backgroundVideo[index] && !this.$refs.backgroundVideo[index].played.length && blockIsActiveDown) {
          this.$refs.backgroundVideo[index].play()
        }
        this.$set(
          this.blocksActiveDown,
          index,
          blockIsActiveDown
        )
      })
    }
  }
}
