export default (_, inject) => {
  const url = '/actions/formie/submissions/submit'

  const post = formData => fetch(url, {
    method: 'post',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json'
    },
    body: formData
  })
    .then((res) => {
      return res.json()
    })

  inject('formie', post)
}
