import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragment-types.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

export default ({ env, route }) => {
  const token = route.query.token || ''
  const query = token ? '?token=' + token : ''

  return {
    httpEndpoint: env.graphqlUrl + query,
    cache: new InMemoryCache({ fragmentMatcher })
  }
}
