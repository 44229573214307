
export default {
  layout (context) {
    if (!context.app.nuxt.err || context.app.nuxt.err.statusCode === 500) {
      return 'simple'
    }

    return 'default'
  },
  props: {
    error: { type: Object, required: false, default: () => {} }
  }
}
