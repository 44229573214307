
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  props: {
    open: { type: Boolean, required: false, default: false }
  },
  watch: {
    open (val) {
      if (val) {
        disableBodyScroll(this.$refs.modalBody)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
      enableBodyScroll(this.$refs.modalBody)
    }
  }
}
