export const state = () => ({
  isRendered: true,
  isVisible: true
})

export const mutations = {
  setIsRendered (state, payload) {
    state.isRendered = payload
  },
  setIsVisible (state, payload) {
    state.isVisible = payload
  }
}
