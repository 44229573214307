import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export function createRouter (ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host

  return new Router({
    ...options,
    routes: extendedRoutes(options.routes, hostname)
  })
}

// extend Nuxt router
function extendedRoutes (defaultRoutes, hostname) {
  // if there's no www in hostname and there's locale subdomain for e.g. us.discovericl.com
  // remove all /locale-switcher routes (we don't want /locale-switcher route to be accessible via browser when we have locale selected)
  // if there's www like www.discovericl.com, we want to serve locale-switcher page as index page
  // to do that we filter default Nuxt routes for /locale-switcher path and change it routes to /
  // all other routes will be disabled in that case

  if (/^www/.test(hostname)) {
    return defaultRoutes
      .filter(r => r.path === '/locale-switcher')
      .map(r => ({ ...r, path: '/' }))
  } else {
    return defaultRoutes
      .filter(r => r.path !== '/locale-switcher')
  }
}
