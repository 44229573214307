
export default {
  props: {
    src: { type: String, required: true },
    ariaLabel: { type: String, default: '' }
  },
  computed: {
    dynamicComponent () {
      if (!this.src) {
        return null
      }
      const svgPath = this.src.replace(/^~\/assets\/svg\//, '')
      return () => import(`~/assets/svg/${svgPath}?inline`)
    }
  }
}
