
import { gql } from 'graphql-tag'
export default {
  props: {
    field: { type: Array, default: null }
  },
  computed: {
    content () {
      if (this.field && this.field.length) {
        return {
          ...this.field.find(block => block.typeHandle === 'main'),
          stats: this.field.filter(block => block.typeHandle === 'stat')
        }
      } else {
        return null
      }
    }
  },
  fragments: {
    field: gql`
      fragment stats on stats_MatrixField {
        ... on stats_main_BlockType {
          id
          typeHandle
          idName
          badgeCopy
          badgeHyperlink {
            type
            url
            text
            target
          }
        }
        ... on stats_stat_BlockType {
          id
          typeHandle
          number
          text
          button {
            type
            url
            text
            target
          }
          badgeCopy
          badgeHyperlink {
            type
            url
            text
            target
          }
        }
      }
    `
  }
}
