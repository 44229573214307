// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/svg/icons/fill/arrow-down.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=ltr] .a-select{padding-right:40px}[dir=rtl] .a-select{padding-left:40px}.a-select{-webkit-appearance:none;-moz-appearance:none;appearance:none;--bg-opacity:1;background-color:#fff;background-color:rgba(255,255,255,var(--bg-opacity));display:block;border-width:1px;--border-opacity:1;border-color:#d9dedf;border-color:rgba(217,222,223,var(--border-opacity));outline:2px solid transparent;outline-offset:2px;padding:.75rem;border-radius:0;--text-opacity:1;color:#57606c;color:rgba(87,96,108,var(--text-opacity));transition-property:background-color,border-color,color,fill,stroke;width:100%;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") calc(100% - 10px) 50%/34px no-repeat}[dir=rtl] .a-select{background-position:10px 50%}.a-select option{--bg-opacity:1;background-color:#fff;background-color:rgba(255,255,255,var(--bg-opacity));--text-opacity:1;color:#57606c;color:rgba(87,96,108,var(--text-opacity))}.a-select:disabled{opacity:.5}.a-select:required{--border-opacity:1;border-color:#10a8ba;border-color:rgba(16,168,186,var(--border-opacity))}.a-select.invalid{background-color:#fcfcc9;border-color:#fcb281}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
