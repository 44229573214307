
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import throttle from 'lodash/throttle'

export default {
  props: {
    isVisible: { type: Boolean, default: true },
    position: { type: String, default: 'fixed' },
    theme: { type: String, default: 'light' },
    isClear: { type: Boolean, default: false },
    isClearDark: { type: Boolean, default: false },
    hasProgressBar: { type: Boolean, default: false },
    countryCode: { type: String, default: '' },
    iso: { type: String, default: '' },
    brand: { type: String, default: '' },
    iclBaseUrl: { type: String, default: '' },
    doctorsUrl: { type: String, default: '' },
    quizUrl: { type: String, default: '' }
  },
  data () {
    return {
      isOpenBenefitsDropdown: false,
      isOpenMenu: false,
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100),
      windowScrollEvent: null,
      pageScrollProgress: 0
    }
  },
  mounted () {
    this.windowScrollEvent = window.addEventListener('scroll', this.handleWindowScrollThrottled)
  },
  beforeDestroy () {
    if (this.scrollEvent) {
      window.removeEventListener('scroll', this.handleWindowScrollThrottled)
    }
  },
  methods: {
    handleWindowScroll () {
      if (this.hasProgressBar) {
        this.pageScrollProgress = window.pageYOffset / (document.body.scrollHeight - window.innerHeight)
      }
    },
    handleQuizButtonClick () {
      //
    },
    handleDoctorsButtonClick () {
      //
    },
    openMenu () {
      this.isOpenMenu = true
      disableBodyScroll(this.$refs.menu.$el)
    },
    closeMenu () {
      this.isOpenMenu = false
      enableBodyScroll(this.$refs.menu.$el)
    },
    handleNavigationLinkClick (event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'headerClicks',
        event_category: 'Header Clicks',
        event_action: event.currentTarget.innerText,
        event_label: event.currentTarget.className
      })
    }
  }
}
