
import GetVideoId from 'get-video-id'

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    controls: {
      type: Boolean
    },
    playsinline: {
      type: Boolean
    }
  },
  data () {
    return {
      player: null
    }
  },
  computed: {
    meta () {
      return GetVideoId(this.src)
    }
  },
  mounted () {
    this.setPlayer()
  },
  methods: {
    async setPlayer () {
      let player
      if (this.meta.service === 'youtube') {
        const { default: YouTubePlayer } = await import('youtube-player')
        player = YouTubePlayer(this.$refs.content, {
          videoId: this.meta.id,
          playerVars: {
            controls: this.controls ? 1 : 0,
            playsinline: this.playsinline ? 1 : 0,
            modestbranding: 1
          }
        })
      } else if (this.meta.service === 'vimeo') {
        const { default: VimeoPlayer } = await import('@vimeo/player')
        player = new VimeoPlayer(this.$refs.content, {
          id: this.meta.id,
          controls: this.controls,
          playsinline: this.playsinline
        })
      } else if (this.meta.service === 'cloudflare') {
        const { default: Hls } = await import('hls.js')
        if (Hls.isSupported() && this.src != null) {
          player = document.querySelector('video')
          const hls = new Hls()
          hls.attachMedia(player)
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(
              this.src
            )
          })
        }
      } else {
        player = this.$refs.content
      }
      this.player = player
    },
    play () {
      if (this.meta.service === 'youtube') {
        this.player.playVideo()
      } else if (this.meta.service === 'vimeo') {
        this.player.play()
      } else {
        this.player.play()
      }
    },
    pause () {
      if (this.meta.service === 'youtube') {
        this.player.pauseVideo()
      } else if (this.meta.service === 'vimeo') {
        this.player.pause()
      } else {
        this.player.pause()
      }
    }
  }
}
