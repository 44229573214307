export const methods = {
  getUri (value) {
    const url = new URL(value)
    return url.pathname
  },
  getCookie (cookies, name) {
    const cookie = {}
    cookies.split(';').forEach(function (el) {
      const [k, v] = el.split('=')
      cookie[k.trim()] = v
    })
    return cookie[name]
  },
  formatLocale (locale) {
    return locale.toLowerCase().replace('-', '_')
  },
  createLocaleUrl (subdomain) {
    const domain = process.env.baseDomain || process.env.BASE_DOMAIN // Server side fix
    const regex = /local$/
    if (process.env.NODE_ENV !== 'development' && !regex.test(domain)) {
      return `https://${subdomain}.${domain}`
    } else {
      return `http://${subdomain}.${domain}:3000`
    }
  }
}

export default ({ app }, inject) => {
  inject('util', methods)
}
