import { render, staticRenderFns } from "./c-cta-box.vue?vue&type=template&id=2a560589&"
import script from "./c-cta-box.vue?vue&type=script&lang=js&"
export * from "./c-cta-box.vue?vue&type=script&lang=js&"
import style0 from "./c-cta-box.vue?vue&type=style&index=0&id=2a560589&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AButton: require('/vercel/path0/components/atoms/a-button.vue').default})
