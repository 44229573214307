import { render, staticRenderFns } from "./c-about-video.vue?vue&type=template&id=1678b53c&"
import script from "./c-about-video.vue?vue&type=script&lang=js&"
export * from "./c-about-video.vue?vue&type=script&lang=js&"
import style0 from "./c-about-video.vue?vue&type=style&index=0&id=1678b53c&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ASvg: require('/vercel/path0/components/atoms/a-svg.vue').default,AButton: require('/vercel/path0/components/atoms/a-button.vue').default,MVideoModal: require('/vercel/path0/components/molecules/m-video-modal.vue').default})
