
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      active: false,
      animated: false,
      lottieAnimation: null,
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100)
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  fragments: {
    field: gql`
      fragment counter on counter_MatrixField {
        ... on counter_block_BlockType {
          id
          typeHandle
          idName
          heading
          copy
          hyperlink {
            type
            url
            text
            target
          }
          button {
            type
            url
            text
            target
          }
        }
      }
    `
  },
  mounted () {
    if (this.content) {
      this.active = false
      window.addEventListener('scroll', this.handleWindowScrollThrottled)
      this.handleWindowScroll()
      this.loadLottieAnimation()
    }
  },
  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.handleWindowScrollThrottled)
    }
  },
  methods: {
    handleWindowScroll () {
      this.active = (this.$refs.container.getBoundingClientRect().top <= window.innerHeight * 0.5)
      if (!this.active && this.lottieAnimation) {
        this.animated = false
        this.lottieAnimation.goToAndStop(0)
      }

      if (this.active && !this.animated && this.lottieAnimation) {
        this.lottieAnimation.goToAndPlay(0)
        this.animated = true
      }
    },
    async loadLottieAnimation () {
      const { default: lottie } = await import('lottie-web')
      this.lottieAnimation = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/animations/30-minute-counter.json'
      })
    }
  }
}
