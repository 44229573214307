import { render, staticRenderFns } from "./c-faq-slider.vue?vue&type=template&id=70d0e46f&"
import script from "./c-faq-slider.vue?vue&type=script&lang=js&"
export * from "./c-faq-slider.vue?vue&type=script&lang=js&"
import style0 from "./c-faq-slider.vue?vue&type=style&index=0&id=70d0e46f&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AVideo: require('/vercel/path0/components/atoms/a-video.vue').default,ASvg: require('/vercel/path0/components/atoms/a-svg.vue').default,AButton: require('/vercel/path0/components/atoms/a-button.vue').default,MPaginationDots: require('/vercel/path0/components/molecules/m-pagination-dots.vue').default})
