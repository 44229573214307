
import { gql } from 'graphql-tag'
import throttle from 'lodash/throttle'
import Hls from 'hls.js'

export default {
  props: {
    field: { type: Array, default: null }
  },
  data () {
    return {
      animate: [],
      animateDown: [],
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100)
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field : null
    },
    validatedContent () {
      return this.content.filter((block) => {
        return this.validateData(block)
      })
    }
  },
  fragments: {
    field: gql`
      fragment splitTestimonial on splitTestimonial_MatrixField {
        ... on splitTestimonial_block_BlockType {
          id
          typeHandle
          idName
          layout
          testimonial {
            id
            title
            uri
            typeHandle
            ... on testimonials_testimonials_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 530, height: 668, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
              testimonialBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
              testimonialMobileBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
            }
            ... on testimonialPages_testimonialPages_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 530, height: 668, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
              testimonialBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
              testimonialMobileBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
            }
          }
        }
      }
    `,
    field2: gql`
      fragment splitTestimonial2 on splitTestimonial2_MatrixField {
        ... on splitTestimonial2_block_BlockType {
          id
          typeHandle
          idName
          layout
          testimonial {
            id
            title
            uri
            typeHandle
            ... on testimonials_testimonials_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 530, height: 668, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
              testimonialBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
              testimonialMobileBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
            }
            ... on testimonialPages_testimonialPages_Entry {
              testimonialOccupation
              testimonialQuote
              testimonialImage {
                url @transform(mode: "crop", width: 530, height: 668, immediately: true)
                title
              }
              testimonialVideo {
                url
                title
                embeddedAsset {
                  url
                  title
                }
              }
              testimonialBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
              testimonialMobileBackgroundVideo {
                id
                url
                title
                embeddedAsset {
                  title
                  url
                  providerName
                }
              }
            }
          }
        }
      }
    `
  },
  mounted () {
    if (this.content) {
      this.animate = new Array(this.validatedContent.length).fill(false)
      this.animateDown = new Array(this.validatedContent.length).fill(false)
      window.addEventListener('scroll', this.handleWindowScrollThrottled)
      this.handleWindowScroll()

      for (let i = 0; i < this.content.length; i++) {
        if (this.content[i].testimonial[0].testimonialBackgroundVideo[0]?.url.length) {
          const backgroundVideo = document.getElementById('testimonialBackgroundVideo' + this.content[i].testimonial[0].testimonialBackgroundVideo[0].id)

          if (this.content[i].testimonial[0].testimonialBackgroundVideo[0].embeddedAsset?.url.length) {
            if (Hls.isSupported()) {
              const hls = new Hls()

              hls.attachMedia(backgroundVideo)
              hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                hls.loadSource(
                  this.content[i].testimonial[0].testimonialBackgroundVideo[0].embeddedAsset.url
                )
              })
            } else if (backgroundVideo.canPlayType('application/vnd.apple.mpegurl')) {
              backgroundVideo.src = this.content[i].testimonial[0].testimonialBackgroundVideo[0].embeddedAsset.url
            }
          } else {
            backgroundVideo.src = this.content[i].testimonial[0].testimonialBackgroundVideo[0].url
          }
        }
      }
    }
  },
  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.handleWindowScrollThrottled)
    }
  },
  methods: {
    handleWindowScroll () {
      for (let i = 0, len = this.validatedContent.length; i < len; i++) {
        const element = this.$refs[`section${i}`][0]
        this.$set(this.animate, i, (element.getBoundingClientRect().top <= window.innerHeight * 0.5) && (element.getBoundingClientRect().bottom >= window.innerHeight * 0.5))
        this.$set(this.animateDown, i, (element.getBoundingClientRect().top <= window.innerHeight * 0.5))
      }
    },
    validateData (block) {
      return (
        block.testimonial.length &&
        block.testimonial[0].testimonialImage.length &&
        block.testimonial[0].testimonialImage[0].url &&
        block.testimonial[0].testimonialQuote &&
        block.testimonial[0].title &&
        block.testimonial[0].testimonialOccupation
      )
    }
  }
}
