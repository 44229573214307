export const state = () => ({
  isRendered: true,
  isVisible: true,
  position: 'fixed',
  theme: 'light',
  isClear: false,
  isClearDark: false,
  hasProgressBar: false
})

export const mutations = {
  setIsRendered (state, payload) {
    state.isRendered = payload
  },
  setIsVisible (state, payload) {
    state.isVisible = payload
  },
  setPosition (state, payload) {
    state.position = payload
  },
  setTheme (state, payload) {
    state.theme = payload
  },
  setIsClear (state, payload) {
    state.isClear = payload
  },
  setIsClearDark (state, payload) {
    state.isClearDark = payload
  },
  setHasProgressBar (state, payload) {
    state.hasProgressBar = payload
  }
}
