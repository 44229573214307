
import { gql } from 'graphql-tag'

export default {
  props: {
    field: { type: Array, default: null }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  fragments: {
    field: gql`
      fragment centeredCopy on centeredCopy_MatrixField {
        ... on centeredCopy_block_BlockType {
          id
          typeHandle
          idName
          heading
          copy
          notes
          showReferencesButton
        }
      }
    `
  }
}
