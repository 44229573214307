import Vue from 'vue'
import ResizeObserver from 'resize-observer-polyfill'
import { theme } from '~tailwind.config'

const media = {}

media.screens = {}
for (const [key, value] of Object.entries(theme.screens)) {
  media.screens[key] = `(min-width: ${value})`
}

const windowVue = new Vue({
  data () {
    return {
      innerHeight: 0,
      pageYOffset: 0,
      document: {
        documentElement: {
          scrollHeight: 0
        }
      },
      matchMedia: {}
    }
  },
  created () {
    this.setInnerHeight()
    this.setPageYOffset()
    this.setDocumentDocumentElementScrollHeight()
    this.setMatchMedia()
  },
  methods: {
    setInnerHeight () {
      if (process.client) {
        this.innerHeight = window.innerHeight
        document.documentElement.style.setProperty('--window-inner-height', window.innerHeight + 'px')
        window.addEventListener('resize', this.setInnerHeight)
      }
    },
    setPageYOffset () {
      if (process.client) {
        this.pageYOffset = window.pageYOffset
        window.addEventListener('scroll', this.setPageYOffset)
      }
    },
    setDocumentDocumentElementScrollHeight (event) {
      if (process.client) {
        this.$set(this.document.documentElement, 'scrollHeight', window.document.documentElement.scrollHeight)
        const resizeObserver = new ResizeObserver((entries) => {
          entries.forEach(() => {
            this.$set(this.document.documentElement, 'scrollHeight', window.document.documentElement.scrollHeight)
          })
        })
        resizeObserver.observe(window.document.documentElement)
      }
    },
    setMatchMedia () {
      for (const [featureKey, featureValue] of Object.entries(media)) {
        this.$set(this.matchMedia, featureKey, {})
        for (const [key, value] of Object.entries(featureValue)) {
          if (process.client) {
            const mediaQueryList = window.matchMedia(value)
            this.$set(this.matchMedia[featureKey], key, mediaQueryList)
            mediaQueryList.addListener((event) => {
              this.$set(this.matchMedia[featureKey], key, event)
            })
          } else {
            const mediaQueryList = { media: value, matches: false }
            this.$set(this.matchMedia[featureKey], key, mediaQueryList)
          }
        }
      }
    }
  }
})

export default ({ app }, inject) => {
  inject('window', windowVue)
}
